import { createRouter, createWebHistory } from 'vue-router';
import Session from 'supertokens-web-js/recipe/session';
import getUser from '@/services/user-info.js'
//import backendRequest from '@/services/backendRequest.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('../views/reset-password.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue'),
  },
  {
    path: '/pspdfkit',
    name: 'PSPFDKitView',
    component: () => import('../views/PSPDFKit.vue'),
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: () => import('../views/Sandbox.vue'),
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../views/Favorites.vue'),
  },
  {
    path: '/select',
    name: 'Select',
    component: () => import('../views/Select.vue'),
  },
  {
    path: '/machine/:id',
    name: 'Machine',
    component: () => import('../views/Machine.vue'),
    props: true,
  },
  {
    path: '/machineinfo/:id',
    name: 'MachineInfo',
    component: () => import('../views/MachineInfo.vue'),
    props: true,
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('../views/Messages.vue'),
  },
  {
    path: '/usermanagement',
    name: 'Settings',
    component: () => import('../views/UserManagement.vue'),
  },
  {
    path: '/section/:id',
    name: 'Section',
    component: () => import('../views/Section.vue'),
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/Article.vue'),
    props: true,
  },
  {
    path: '/pdfview/:postid',
    name: 'PdfView',
    component: () => import('../views/PdfView.vue'),
    props: true,
  },
  {
    path: '/Content_edit/:id',
    name: 'Content_edit',
    component: () => import('../views/Content_edit.vue'),
    props: true,
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: () => import('../views/Page.vue'),
    props: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/403.vue'),
    props: true,
  },
  {
    path: '/usermanagement',
    name: 'UserManagement',
    component: () => import('../views/UserManagement.vue'),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});
router.beforeEach(async (to/*, from*/) => {
  //console.log("TO:",to);
  return Session.doesSessionExist().then(async value => {
    let checkUser = await getUser();
    if ((!value || checkUser === null) && to.name !== 'login' && to.name !== 'reset-password') {
      localStorage.removeItem("bOpus_user");
      return '/login';
    } else if (value && to.name !== 'Settings' && checkUser !== null && checkUser.metadata.changePass === 'true') {
      console.log('checkUserChangePass', checkUser.metadata.changePass);
      console.log('checkUserChangePassToName', to.name);
      return '/usermanagement';
    } else if (value && (to.name === 'login' || to.name === undefined)) {
      return '/';
    }
  });
});

export default router;
