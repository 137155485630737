import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import getUser from './services/user-info.js'
import store from './store';
import mitt from 'mitt';
import './registerServiceWorker';
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts';
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from 'highcharts/modules/solid-gauge';
import '../src/components/universal_styles.vue';
import VueHighlightJS from 'vue3-highlightjs';
import '../src/assets/highlight-js-theme.css';
import { ImagesControl } from './services/ImagesControl.js';
import Vue3TouchEvents from "vue3-touch-events";

fetch('/config.json').then(res => res.json()).then(async function (config) {
  // alert(config.api);
  const emitter = mitt();

  HighchartsMore(Highcharts);
  SolidGauge(Highcharts);
  const app = createApp(App);
  app.config.globalProperties.emitter = emitter;
  app.provide('apiPath', config.api);
  app.provide('build', config.build);
  app.provide('cloudflareLink', config.cfLink);
  if (window.location.host.startsWith('localhost')) {
    app.provide('pspdfkitlkey', config.pspdfkitlkeyl);
  } else {
    app.provide('pspdfkitlkey', config.pspdfkitlkey);
  }
  app.provide('imagesControl', new ImagesControl());
  app.use(store);
  app.use(router);
  app.use(HighchartsVue);
  app.use(VueHighlightJS);
  app.use(Vue3TouchEvents);
  app.provide('user', (localStorage.getItem('bOpus_user') !== null ? JSON.parse(localStorage.getItem('bOpus_user')) : null));
  app.mount('#app');

});



