<template>
  <div class="button"></div>
</template>

<script>
export default {
  name: 'btn',
};
</script>

<style lang="scss">
.button{
  text-align: center;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  // padding: 0 50px 0 50px;
  font-size: 1rem;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: #006C3E;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  border-color: #006C3E;
  transition: all .1s;
  &:hover{
    border-color: rgba(0, 108, 62, 0.15);
    background-color: rgba(0, 108, 62, 0.15);
    color: #006C3E;
  }
  &:active{
    border-color: #004D2C;
    background-color: #004D2C;
    color: #fff;
  }
  &.disabled{
    cursor: default;
    border-color: #bcbcbc;
    color: #fff;
    background-color: #bcbcbc;
    &:hover{
      border-color: #bcbcbc;
      color: #fff;
      background-color: #bcbcbc;
    }
  }
  &.secondary{
    border-color: #787878;
    color: #787878;
    background-color: #fff;
    &:hover{
      border-color: #006C3E;
      color: #006C3E;
      background-color: #fff;
    }
    &:active{
      border-color: #004D2C;
      background-color: #004D2C;
      color: #fff;
    }
    &.disabled{
      border-color: #bcbcbc;
      color: #bcbcbc;
      background-color: #fff;
      &:hover{
        border-color: #bcbcbc;
        color: #bcbcbc;
        background-color: #fff;
      }
    }
  }
}

.right{
  background-image: url(../../../public/img/arr.svg);
  background-repeat: no-repeat;
  background-position: center right;
  padding: 0 50px 0 24px;
  &:hover{
    background-image: url(../../../public/img/arr_green.svg);
  }
  &:active{
    background-image: url(../../../public/img/arr.svg);
  }
  &.disabled{
    background-image: url(../../../public/img/arr.svg);
  }
  &.secondary{
    background-image: url(../../../public/img/arr_gray.svg);
    &:hover{
      background-image: url(../../../public/img/arr_green.svg);
    }
    &:active{
      background-image: url(../../../public/img/arr.svg);
    }
    &.disabled{
      background-image: url(../../../public/img/arr_l_gray.svg);
    }
  }
}

.left{
  background-image: url(../../../public/img/plus.svg);
  background-repeat: no-repeat;
  background-position: 40px center;
  padding: 0 24px 0 70px;
  &:hover{
    background-image: url(../../../public/img/plus_green.svg);
  }
  &:active{
    background-image: url(../../../public/img/plus.svg);
  }
  &.disabled{
    background-image: url(../../../public/img/plus.svg);
  }
  &.secondary{
    background-image: url(../../../public/img/plus_gray.svg);
    &:hover{
      background-image: url(../../../public/img/plus_green.svg);
    }
    &:active{
      background-image: url(../../../public/img/plus.svg);
    }
    &.disabled{
      background-image: url(../../../public/img/plus_l_gray.svg);
    }
  }
}
</style>
